<template>
  <v-card tile style="width: auto" class="elevation-0 h-100">
    <div class="d-inline-flex w-100 h-100">
      <v-card
        v-for="i in items"
        :key="i.text"
        class="elevation-0"
        tile
        :min-width="minWidth"
        :style="`width: inherit; background:${bgColor}; `"
      >
        <v-card-title
          v-if="!parseAttrs('smTitle') && !parseAttrs('smTitleBold')"
          class="settingTitle text-no-wrap py-2 text-subtitle-1"
          :style="`background:${color}; color:${textColor}`"
        >
          {{ i.text }}
        </v-card-title>
        <v-card-title
          v-else
          class="settingTitle text-no-wrap py-2 caption"
          :class="parseAttrs('smTitleBold') ? 'font-weight-bold' : ''"
          :style="`background:${color}; color:${textColor}`"
        >
          {{ i.text }}
        </v-card-title>
        <v-card-text
          v-if="!parseAttrs('noPadding')"
          class="grey--text text--darken-4 py-2 px-1"
        >
          <slot :name="i.value" />
        </v-card-text>
        <slot v-else :name="i.value" />
      </v-card>
    </div>

    <v-list-item
      v-show="$attrs.action"
      class="justify-start elevation-0 align-center"
    >
      <v-btn icon x-small style="background: #00b1db" @click="$attrs.action">
        <v-icon color="white">mdi-plus</v-icon>
      </v-btn>
    </v-list-item>
  </v-card>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        { text: "Y分數區間", value: "yZone" },
        { text: "對應類型", value: "refType" },
        { text: "CR", value: "cr" },
      ],
    },
    color: {
      type: String,
      default: "#00b1db",
    },
    bgColor: {
      type: String,
      default: "#fff",
    },
    textColor: {
      type: String,
      default: "#fff",
    },
    minWidth: {
      type: String,
      default: "100",
    },
  },
  data() {
    return {
      // items: [
      //   { text: "Y分數區間", value: "yZone" },
      //   { text: "對應類型", value: "refType" },
      //   { text: "CR", value: "cr" },
      // ],
    };
  },
  mounted() {},
  methods: {
    parseAttrs(attr) {
      return Object.keys(this.$attrs).includes(attr);
    },
  },
};
</script>
<style scoped>
/* .cardTextStyle {
  height: 100px;
}
.itemStyle {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 55px;
}
.btnStyle {
  background-color: #00b1db;
} */
.settingTitle {
  color: white;
  justify-content: center;
  font-size: 18px;
}

/* .fieldStyle {
  max-width: 80px;
}
.textfieldStyle {
  max-width: 50px;
} */
</style>
