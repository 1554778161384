<template>
  <v-container fluid>
    <!-- === Login === -->
    <h2>Login</h2>
    <v-btn @click="login" color="primary">登入</v-btn>
    <v-divider class="my-5"></v-divider>
    <!-- === status chip === -->
    <h2>Status Chip</h2>
    <chip-status class="mr-2"></chip-status>
    <chip-status
      v-for="status in 4"
      class="mr-2"
      :key="status"
      :status="status"
    ></chip-status>
    <v-divider class="my-5"></v-divider>

    <!-- === import dialog === -->
    <h2>Import Dialog</h2>
    <v-btn
      @click="importDialogOpen('unitField')"
      color="primary"
      class="elevation-0 mr-2"
      >單位領域</v-btn
    >
    <v-btn
      @click="importDialogOpen('riskEvaluation')"
      color="primary"
      class="elevation-0 mr-2"
      >風險評估</v-btn
    >
    <v-btn
      @click="importDialogOpen('auditPlan')"
      color="primary"
      class="elevation-0"
      >稽核計畫</v-btn
    >
    <dialog-import
      @close="importDialog.open = false"
      @reset="importDialog.errors = []"
      @submit="importSubmit"
      v-bind="importDialog"
    ></dialog-import>
    <v-divider class="my-5"></v-divider>

    <!-- === expand and collapse === -->
    <h2>Expand</h2>
    <v-btn @click="isExpand = !isExpand" fab small class="elevation-1">
      <v-icon v-if="!isExpand" color="#00b1db">mdi-chevron-right</v-icon>
      <v-icon v-else color="#00b1db">mdi-chevron-left</v-icon>
    </v-btn>
    <div :class="isExpand ? 'active' : ''" class="expand">
      <allocation-form-table :action="false"></allocation-form-table>
    </div>
    <v-divider class="my-5"></v-divider>

    <!-- === allocation table === -->
    <h2>Allocation Table</h2>
    <v-btn color="info" class="mb-2" @click="isEdit = !isEdit"
      >Toogle Edit</v-btn
    >
    <allocation-form-table color="#4CAF50" :action="isEdit ? addlist : false">
      <template #yZone>
        <div class="d-flex justify-space-between">
          <div>
            <div>test</div>
            <div>test</div>
            <div>test</div>
            <div>test</div>
            <div>test</div>
          </div>

          <v-divider vertical></v-divider>
        </div>
        <!-- <AllocationFormActions /> -->
      </template>
      <template #refType>
        <div>refType</div>
        <div>test</div>
        <div>test</div>
        <div>test</div>
        <div>test</div>
      </template>
      <!-- <template #actions>
        <AllocationFormActions />
      </template> -->
    </allocation-form-table>
  </v-container>
</template>
<script>
import AllocationFormTable from "../risk/components/RiskEvaluationFormTable.vue";
import { apiAuthBySSOToken } from "../../api/auth";
export default {
  components: {
    AllocationFormTable,
  },
  data() {
    return {
      // === Login ===
      async login() {
        try {
          let res = await apiAuthBySSOToken({
            system: "ro",
            ssoToken: "Token received from EIP AMS redirect request parameter",
          });
          console.log(res);
        } catch (err) {
          console.log(err);
        }
      },
      // === import dialog ===
      importDialog: {
        // open: false,
        // type: "unitField",
        // errors: [],
      },
      // === expand and collapse ===
      isExpand: true,
      // === allocation table ===
      isEdit: true,
    };
  },
  methods: {
    // === import dialog ===
    importDialogOpen(type) {
      this.importDialog = {
        open: true,
        type,
      };
    },
    importSubmit() {
      console.log("submit");
      // TODO: Deal with submit
      // ------------
      //if Error
      this.importDialog = {
        ...this.importDialog,
        errors: ["格式錯誤", "缺少XX欄位"],
      };
    },
    // === allocation table ===
    addlist() {
      console.log("add");
    },
  },
};
</script>
<style scoped lang="scss">
.expand {
  width: 0;
  height: 100px;
  overflow: hidden;
  transition: width 0.4s ease-in-out;
}
.active {
  width: 100%;
}
</style>
